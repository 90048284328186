import { useState, useRef, useEffect } from "react";

import gsap from "gsap";

import { isDesktop, isLaptop, isTab, updateMetaTags } from "utility/utils";
import { OUT_COMMITMENT } from "utility/commonData";
import { Swiper, SwiperSlide } from "swiper/react";

//prettier-ignore
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";
import logo from "Assets/Image/Common/logo.png";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/mousewheel";

const Commitment = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);
  const itemRefs = useRef([]);

  const handleTabClick = (index) => {
    setActiveIndex(index);
    swiperRef.current?.slideToLoop(index);
  };

  const sliderSettings = {
    className: "w-full h-full shadow-2xl shadow-primary",
    slidesPerView: 1,
    centeredSlides: true,
    simulateTouch: false,
    spaceBetween: 15, 
    pagination: isTab() ? false : { clickable: true },
    modules: [Pagination, Autoplay, EffectCoverflow],
    onSlideChange: (swiper) => setActiveIndex(swiper.realIndex),
    initialSlide: activeIndex,
    onSwiper: (swiper) => (swiperRef.current = swiper),
    speed: 2000,
    effect: "coverflow",
    coverflowEffect: {
      rotate: 30,
      stretch: 0,
      depth: 100,
    },
  };

  useEffect(() => {
    const totalItems = OUT_COMMITMENT.length;
    const radius = isDesktop() ? 160 : isLaptop() ? 190 : 240;
    const angleStep = 360 / totalItems;

    itemRefs.current.forEach((el, idx) => {
      if (el) {
        // Start from top (-90 degrees)
        const angle = -90 + angleStep * idx;
        const x = radius * Math.cos((angle * Math.PI) / 180);
        const y = radius * Math.sin((angle * Math.PI) / 180);

        // GSAP for smoother positioning
        gsap.set(el, { x: 0, y: 0, opacity: 0 });
        gsap.to(el, { x, y, duration: 0.1, delay: idx * 0.25, opacity: 1 });
      }
    });
  }, []);

  useEffect(() => {
    updateMetaTags({
      title: "Our Commitment - Transparency, Ethics & Lasting Partnerships | Diamond Manufacturing",
      description: "We prioritize client satisfaction through ethical business practices, transparent communication, and long-term partnerships built on trust and mutual success.",
      keywords: "conflict-free diamonds, ethical diamond sourcing, transparent diamond supply chain, diamond industry ethics, trusted diamond manufacturer, responsible diamond sourcing, diamond partnership",
      canonical: "https://www.arjivexports.com/commitment"
    });
  }, []);  

  return (
    <main className="md:pt-24 pt-20 bg-primary">
      <section className="overflow-hidden bg-offwhite bg-shapes-bg bg-cover bg-left pt-8 sm:pb-16 pb-8 flex flex-col justify-center text-primary">
        <h1 className="sm:text-4xl text-2xl sm:mb-7 mb-7 border-b-2 max-w-max mx-auto px-3 font-mont pb-2 border-primary font-bold text-center uppercase">
          Our Commitment to You
        </h1>

        <div className="mx-auto px-4 lg:px-8 grid grid-cols-1 lg:grid-cols-[40%_57%] lg:gap-5 sm:gap-8 gap-5 items-start">
          {/* Hexagonal Section */}
          <div className="relative w-full h-full h-full flex items-center justify-center">
            <div className="hidden lg:flex relative w-full lg:h-full md:h-[50vh] items-center justify-center">
              <div
                className="absolute lg:w-32 lg:h-32 w-24 h-24 rounded-full border-2 border-dashed border-offwhite"
                style={{
                  padding: "3px",
                  position: "relative",
                }}
              >
                {/* Rotating Border */}
                <div className="absolute top-0 left-0 w-full h-full rounded-full border-2 border-dashed border-dark spin-animation opacity-90"></div>

                <img
                  src={logo}
                  className="w-full h-full object-contain invert rounded-full relative z-10 p-2"
                  alt="Arjiv Exports"
                />
              </div>

              {OUT_COMMITMENT.map((x, index) => (
                <div
                  key={index}
                  ref={(el) => (itemRefs.current[index] = el)}
                  className={`absolute cursor-pointer transition-all flex flex-col items-center rounded-full`}
                  onClick={() => handleTabClick(index)}
                >
                  <img
                    src={x.img}
                    className={`xl:w-28 xl:h-28 w-20 h-20 object-contain rounded-full transition-all duration-300 opacity-90 ${
                      activeIndex === index
                        ? "bg-white invert border-4 p-1 border-offwhite shadow-lg shadow-border"
                        : ""
                    }`}
                    alt={x.title}
                  />
                  <p className="font-mont sm:text-base text-xs max-w-[150px] text-center mt-2">
                    {x.title}
                  </p>
                </div>
              ))}
            </div>

            {/* Only for small screens */}
            <div className="grid grid-cols-6 gap-4 lg:hidden pb-2">
              {OUT_COMMITMENT.map((x, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center cursor-pointer"
                  onClick={() => handleTabClick(index)}
                >
                  <img
                    src={x.img}
                    className={`w-20 h-full object-contain rounded-full transition-all duration-300 ${
                      activeIndex === index
                        ? "bg-white invert border-4 p-1 border-offwhite"
                        : ""
                    }`}
                    alt={x.title}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Right Side - Swiper */}
          <div className="overflow-hidden xl:px-0 lg:px-5 lg:min-h-[75vh] h-full shadow-2xl shadow-primary bg-primary">
            <Swiper {...sliderSettings}>
              {OUT_COMMITMENT.map((x, index) => (
                <SwiperSlide key={index}>
                  <div className="bg-primary text-white xl:px-24 sm:p-8 p-4 h-full flex flex-col justify-center text-center sm:pb-8 pb-16">
                    <div className="aspect-square sm:w-40 sm:h-40 w-24 h-24 flex items-center justify-center mx-auto">
                      <img
                        src={x.img}
                        alt="Commitment"
                        className="md:w-full md:h-full w-28 h-28 object-contain rounded-full invert"
                      />
                    </div>
                    <h3 className="md:text-4xl sm:text-2xl text-xl font-bold sm:my-6 my-4 px-3 sm:pb-4 pb-2 font-mont border-b-2 max-w-max mx-auto">
                      {x.title}
                    </h3>
                    <p className="md:text-xl sm:text-lg text-sm max-w-2xl mx-auto">
                      {x.desc}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Commitment;
