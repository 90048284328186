import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "Components/Header";
import Footer from "Components/Footer";
import OriginAlliance from "Components/OriginAlliances";

import CSR from "Pages/CSR";
import Home from "Pages/Home";
import Events from "Pages/Events";
import SiteMap from "Pages/Sitemap";
import Journey from "Pages/Journey";
import Grading from "Pages/Grading";
import Policies from "Pages/Policies";
import NotFound from "Pages/NotFound";
import WhatWeDo from "Pages/WhatWeDo";
import Contactus from "Pages/ContactUs";
import Rjcpolicy from "Pages/RJCPolicy";
import Provenance from "Pages/Provenance";
import Customization from "Pages/Customization";
import Commitment from "Pages/Commitment";

// import Story from "Components/Story";

import { Toaster } from "react-hot-toast";

import Cookie from "Components/Cookie";
import "Assets/Scss/Theme.module.scss";

const PREVENT_ORIGIN_PAGES_LIST = ["", "/", "/home"];

function App() {
  const location = useLocation();
  const [showOriginAlliance, setShowOriginAlliance] = useState(true);

  useEffect(() => {
    setShowOriginAlliance(
      !PREVENT_ORIGIN_PAGES_LIST.includes(location.pathname.replace(/\/$/, ""))
    );
  }, [location]);

  function ScrollToTop() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return null;
  }

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <ScrollToTop />
      <Cookie />
      <Header />
      <div className="App">
        <Routes>
          {[
            { path: "/", element: <Home /> },
            { path: "/home", element: <Home /> },
            { path: "/events", element: <Events /> },
            { path: "/contact", element: <Contactus /> },
            { path: "/what-we-do", element: <WhatWeDo /> },
            { path: "/journey", element: <Journey /> },
            { path: "/our-history", element: <Journey /> },
            { path: "/customization", element: <Customization /> },
            { path: "/grading", element: <Grading /> },
            { path: "/policies", element: <Policies /> },
            { path: "/rjcpolicy", element: <Rjcpolicy /> },
            { path: "/provenance", element: <Provenance /> },
            { path: "/csr", element: <CSR /> },
            { path: "/sitemap", element: <SiteMap /> },
            { path: "/commitment", element: <Commitment /> },
            { path: "/*", element: <NotFound /> },
          ].map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Routes>
      </div>
      {showOriginAlliance && <OriginAlliance />}
      <Footer />
    </>
  );
}

export default App;
