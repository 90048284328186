// STORY_OF_ARJIV
import fancy from "Assets/Image/Journey/fancy.png";
import flame from "Assets/Image/Journey/flame.png";
import hexa from "Assets/Image/Journey/hexa.png";
import oldmine from "Assets/Image/Journey/old-mine.png";
import rose from "Assets/Image/Journey/rose-cut.png";
import single from "Assets/Image/Journey/single-cut.png";
import ctp from "Assets/Image/Journey/ctp.png";
import brilliant from "Assets/Image/Journey/brilliant.png";

// OUT_COMMITMENT
import Transparency from "Assets/Image/commitment/Transparency.png";
import ClientExcellence from "Assets/Image/commitment/ClientExcellence.png";
import EthicalStandards from "Assets/Image/commitment/EthicalStandards.png";
import ValueBasedSolutions from "Assets/Image/commitment/ValueBasedSolutions.png";
import MutualBusinessExcellence from "Assets/Image/commitment/MutualBusinessExcellence.png";
import EnduringPartnerships from "Assets/Image/commitment/EnduringPartnerships.png";

export const STORY_OF_ARJIV = [
  {
    year: "1985's",
    title: "Single Cut Diamonds",
    description:
      "A single cut on the other hand is a simple form of cutting a round diamond with only 17 or 18 facets. If you compare a Single Cut Diamond (usually small Carat Weights of 1.2mm or less) , to a Full Cut Diamond (58 facets), you’d quickly see the difference. Small high quality single cut diamonds are highly prized especially in the watch industry, and because there are fewer but larger facets, they give off a majestic dance of light and colour, that is often lost in a sea of white light with the full cut stones",
    img: single,
    alt: "Single Cut Diamonds",
  },
  {
    year: "2000's",
    title: "Rose Cut Diamonds",
    description:
      "The rose cut is one of the oldest cutting style for diamonds mordern jewellery designers are creating edges(Contemparory jewellery with an interesting, old world vibe by adding rose cut.) The ancient art of faceting a stone to look like an opening nose, originated in India in the 16th century. The result is a stone that is still coveted today by high-end designers and consumers alike. This brilliant time-honored cut allows light to reflect within 24 domed facets(Size 0.01 to 20.00 cts), Colour(o-z.natural fancy colour).",
    img: rose,
    alt: "Rose Cut Diamonds",
  },
  {
    year: "2002's",
    title: "Old Mine Cut Diamonds",
    description:
      "Brought back a piece of history by reviving the classic Old Mine Cut. This vintage-style diamond cut harkens back to the 18th century, characterized by its shape, deep facets, and antique charm. By introducing these cuts to our portfolio, we enabled our customers to experience a sense of timeless luxury, connecting them with the craftsmanship of a bygone era.",
    img: oldmine,
    alt: "Old Mine Cut Diamonds",
  },
  {
    year: "2008's",
    title: "Brilliant Cut Diamonds",
    description:
      "A brilliant cut is simple a diamond or other gemstone cut in a particular form with numerous facets so as to have exceptional brilliance. The shape resembles of a cone and provides maximised light return through the top of a diamond. Round Brilliant Cuts are moreover used in modern jewellery.",
    img: brilliant,
    alt: "Brilliant Cut Diamonds",
  },
  {
    year: "2012's",
    title: "Fancy Coloured Diamonds",
    description:
      "Natural, Intense fancy coloured diamonds are as rare as they exquisite. Arjiv exports carries a variety of fancy colours in a range of shapes, sizes, colours and clarity. They are 100% natural and un-treated with heat or chemicals so not to compromise their value or quality.",
    img: fancy,
    alt: "Fancy Coloured Diamonds",
  },
  {
    year: "2015's",
    title: "Step Cut Diamonds",
    description:
      "Stones whose outlines are either square or rectangular and whose facets are rectilinear and arranged parallel to the griddle known as Step-or-step cut stones. These stones often have their corners truncated creating an embrald cut with octagonal outline.",
    img: hexa,
    alt: "Step Cut Diamonds",
  },
  {
    year: "2017's",
    title: "Evolution of Step Cuts",
    description:
      "we embraced innovation by evolving the classic step-cut diamond. With a focus on creating modified cuts, we transformed the traditional step cuts into something more intricate and unique, pushing the boundaries of diamond cutting. This new approach allowed us to offer diamonds with enhanced brilliance and a striking geometric look, catering to modern jewelry trends while maintaining a touch of elegance.",
    img: flame,
    alt: "Evolution of Step Cuts",
  },
  {
    year: "2020's - Present",
    title: "Custom Cuts for Limitless Creativity",
    description:
      "Arjiv Exports has been on the cutting edge of diamond innovation. We now specialize in creating custom cuts that break traditional boundaries, allowing our customers to realize their most imaginative jewelry designs. Whether it’s modifying shapes, angles, or facet arrangements, we work closely with our clients to craft diamonds that fit their exact specifications. From avant-garde cuts to personalized modifications, our bespoke diamond solutions open up new possibilities for jewelry creators worldwide.",
    img: ctp,
    alt: "Custom Cuts",
  },
];

export const OUT_COMMITMENT = [
  {
    title: "Open Supply Chain",
    desc: "At Diamond Manufacturing, we believe in complete visibility. Every diamond we source comes with a documented journey from our conflict-free mine partners to your hands. We share these details not because we have to, but because we're proud of how we do business.",
    img: Transparency,
  },
  {
    title: "Ethical Standards",
    desc: "Our business thrives on integrity, not just profit margins. When you partner with Diamond Manufacturing, you're choosing a company that values fair pricing, honest grading, and ethical sourcing as much as you do. This isn't just how we do business—it's who we are.",
    img: EthicalStandards,
  },
  {
    title: "Client Excellence",
    desc: "Your satisfaction drives everything we do. We measure our success not by transactions completed, but by relationships that last. Our dedicated team provides personalized service and expertise to ensure your specific needs are not just met, but exceeded with every order.",
    img: ClientExcellence,
  },
  {
    title: "Enduring Partnerships",
    desc: "We don't seek one-time sales—we cultivate partnerships that span generations. By understanding your long-term goals and market challenges, we position ourselves as your trusted ally in an ever-changing industry, growing and adapting together.",
    img: EnduringPartnerships,
  },
  {
    title: "Value-Based Solutions",
    desc: "We'll never push inventory or recommend more than what serves your business needs. Our approach is simple: we succeed when you succeed, which means offering only the diamonds that align with your market demands and growth strategy.",
    img: ValueBasedSolutions,
  },
  {
    title: "Mutual Business Excellence",
    desc: "We believe excellence flows both ways. By maintaining the highest standards in our operations, we enable you to do the same. Together, we create a business relationship where quality, reliability, and success are shared commitments, not just aspirations.",
    img: MutualBusinessExcellence,
  },
];
